
import { defineComponent, inject } from 'vue'
import { debounce } from 'debounce'
import { DEBOUNCE_INTERVAL } from '@/config'

import { GetFishesParams, Fish } from '@/data/fish/types'
import { FishRepositoryKey } from '@/data/injectables'

import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import FishListItem from '@/views/fish/FishListItem.vue'
import SearchNavBar from '@/components/navbar/SearchNavBar.vue'
import ActionBar from '@/components/ActionBar.vue'
import ActionButton from '@/components/ActionButton.vue'
import ListWrapper from '@/components/ListWrapper.vue'

export default defineComponent({
  components: {
    FishListItem,
    SearchNavBar,
    ActionBar,
    ActionButton,
    ListWrapper,
  },
  setup: () => {
    const fishRepository = inject(FishRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)
    return { fishRepository, handleError }
  },
  data() {
    return {
      loading: false,
      error: false,
      searchText: '',
      fishes: [] as Fish[],
    }
  },
  watch: {
    searchText() {
      this.loading = true
      this.loadFishesDebounced()
    },
  },
  created() {
    this.loadFishes()
  },
  methods: {
    handleEdit(fishId: string): void {
      this.$router.push({ path: `/fishes/${fishId}/edit` })
    },
    handleCreate(): void {
      this.$router.push({ path: '/fishes/create' })
    },
    async loadFishes(): Promise<void> {
      try {
        this.error = false
        this.loading = true
        const params: GetFishesParams = {}
        params.name = this.searchText || undefined
        const fishes = await this.fishRepository?.getFishes(params)
        this.fishes = fishes || []
      } catch (e) {
        this.error = true
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    loadFishesDebounced: debounce(function (this: { loadFishes: () => void }) {
      this.loadFishes()
    }, DEBOUNCE_INTERVAL),
  },
})
