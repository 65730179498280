
import { Fish } from '@/data/fish/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    fish: {
      type: Object as PropType<Fish>,
      required: true,
    },
  },
  computed: {
    statusStyle(): string {
      return this.fish.inactive ? 'status--inactive' : 'status--active'
    },
  },
})
